@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700;900&display=swap');

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

@font-face {
    font-family: "BebasNeue-Regular";
    src: url("./fonts/bebas-neue/BebasNeue-Regular.eot"); /* IE9 Compat Modes */
    src: url("./fonts/bebas-neue/BebasNeue-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/bebas-neue/BebasNeue-Regular.otf") format("opentype"), /* Open Type Font */
    url("./fonts/bebas-neue/BebasNeue-Regular.svg") format("svg"), /* Legacy iOS */
    url("./fonts/bebas-neue/BebasNeue-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/bebas-neue/BebasNeue-Regular.woff") format("woff"), /* Modern Browsers */
    url("./fonts/bebas-neue/BebasNeue-Regular.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
